import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../../utils/iepFormProvider'
import { useQuery } from '@apollo/client'
//import { QUERY_STUDENT_NAME } from '../../../../operations/queries/forms/SIS'
import { QUERY_STUDENT_COMMON_SIS } from '../../../../operations/queries/forms/SIS'
import { QUERY_HEALTHPLAN_VERSION } from '../../../../operations/queries/forms/HealthPlan'
import moment from 'moment'
import FormErrorProvider, { FormErrorContext } from '../errorProvider'
import FormContainer from '../../../FormContainer'
import {
    Alert,
    Button,
    Card,
    DatePicker,
    Form,
    Input,
    Typography,
    Checkbox,
    Radio,
    Select
} from 'antd'
import FormCollaboration from '../../../FormCollaboration'
import Title from 'antd/lib/typography/Title'
import StudentFullNameSIS from '../sis/StudentFullNameSIS'
import StudentDobSIS from '../sis/StudentDobSIS'
import StudentGradeSIS from '../sis/StudentGradeSIS'
import StudentFormActions from '../StudentFormActions'
import { PRINT_FORMHealthPlan } from '../../../../operations/mutations/forms/HealthPlan'
import Editor from '../../../elements/common/Editor'
const { Paragraph, Text } = Typography

const FormRecord = ({
    data,
    studentFormId,
    studentId,
    formId,
    schoolId,
    studentMeetingId,
    sis,
    versionId
}) => {
    const [form] = Form.useForm()
    const { Option } = Select

    const { itemErrors, formError } = useContext(FormErrorContext)
    const getFormFieldValues = () => {
        const formFieldValues = form.getFieldsValue()

        return {
            ...formFieldValues,
            studentFormId: studentFormId,
            formId: formId,
            studentId: studentId,
            schoolId: schoolId,
            studentMeetingId: studentMeetingId,
        }
    }

    const formatFormFieldUpdate = (fieldName, fieldValue) => {
        //console.log('formatFormFieldUpdate', fieldName, fieldValue)
        const update = Object.assign({})
        switch (fieldName) {
            case 'formDate':
            case 'studentDob':
            case 'startDate':
            case 'endDate':
            case 'dateOfDiagnosis':
                update[fieldName] = fieldValue ? moment(fieldValue) : ''
                break
            case 'disorder':
            case 'behavior':
            case 'goal':
                //console.log('formatFormFieldUpdate:-goal',fieldValue,fieldValue.length,typeof(fieldValue),fieldValue.split('|'),fieldValue === ' ')
                update[fieldName] = fieldValue != ' ' ? fieldValue.split('|') : []
                break
            case 'dismissed':
                update[fieldName] = fieldValue.split(',')
                break
            default:
                update[fieldName] = fieldValue
        }

        return update
    }

    const handleFieldCollaborationUpdate = (fieldName, fieldValue) => {
        const update = formatFormFieldUpdate(fieldName, fieldValue)
        if (fieldName === 'diagnosed') {
            setDiagnosed(fieldValue)
        }
        form.setFieldsValue(update)
    }

    const endOption = [
        { label: 'Student met goal', value: 'metGoal' },
        { label: 'Student moved', value: 'studentMoved' },
        { label: 'Student was placed on alternative placement', value: 'alternativePlacement' },
        { label: 'School year ending', value: 'yearEnding' }
    ]

    const [diagnosed, setDiagnosed] = useState(data.diagnosed)
    const onChangeDiagnosed = (value) => {
        handleFieldCollaborationUpdate('diagnosed', value.target.value)
        setDiagnosed(value.target.value)
    }
    return (
        <>
            <FormCollaboration
                form={form}
                itemErrors={itemErrors}
                studentFormId={studentFormId}
                handleUpdate={handleFieldCollaborationUpdate}
            >
                {({
                    handleMouseEvents,
                    handleFocusEvents,
                    handleEditorEvents,
                    formDisabledState,
                    canSave,
                    validationProps,
                    handleActionEvents
                }) => (
                    <Form
                        form={form}
                        id='studentFormHealthPlan'
                        name='studentFormHealthPlan'
                        initialValues={data}
                        preserve
                        layout='vertical'
                        className='page-padding-leftRight page-padding-topBottom form-labels-bold'
                    >
                        <Title level={4} className='center'>
                            Student Healthcare Plan
                        </Title>
                        <Form.Item
                            label='Form Created Date'
                            name='formDate'
                            {...validationProps('formDate')}
                        >
                            <DatePicker
                                //style={{backgroundColor: "white", borderWidth: 0}}
                                //customStyles={{ formDate: { borderWidth: 0 } }}
                                {...handleFocusEvents}
                                disabled={formDisabledState.formDate}
                                format={[
                                    'MM-DD-YYYY',
                                    'MM/DD/YYYY',
                                    'MM.DD.YY',
                                    'M-D-YYYY',
                                    'M/D/YYYY',
                                    'M.D.YYYY',
                                    'MM.DD.YYYY',
                                    'MM-DD-YY',
                                    'MM/DD/YY',
                                    'M-D-YY',
                                    'M/D/YY',
                                    'M.D.YY',
                                    'MMDDYYYY',
                                    'MMDDYY'
                                ]}
                            />
                        </Form.Item>

                        <Card
                            title='Student SIS Data'
                            style={{
                                marginBottom: 30
                            }}
                        >
                            <Alert
                                message='SIS fields are locked, please contact administrator in case of error.'
                                type='warning'
                                style={{ marginBottom: 20 }}
                            />
                            <StudentFullNameSIS
                                form={form}
                                sis={sis}
                                id={studentId}
                                initialValue={data.studentFullName}
                                error={itemErrors.studentFullName}
                            />

                            <StudentDobSIS
                                form={form}
                                sis={sis}
                                id={studentId}
                                initialValue={data.studentDob}
                                error={itemErrors.studentDob}
                            />
                            <StudentGradeSIS
                                form={form}
                                sis={sis}
                                id={studentId}
                                initialValue={data.studentGrade}
                                error={itemErrors.studentGrade}
                            />
                        </Card>

                        <Card
                            title='Health Services'
                            style={{
                                marginBottom: 30
                            }}
                        >
                            <Form.Item
                                label='Start Date'
                                name='startDate'
                                {...validationProps('startDate')}
                            // valuePropName={moment(form.getFieldValue('startDate'))}
                            // initialValue={moment(form.getFieldValue('startDate'))}
                            >
                                <DatePicker
                                    {...handleFocusEvents}
                                    disabled={formDisabledState.startDate}
                                    format={[
                                        'MM-DD-YYYY',
                                        'MM/DD/YYYY',
                                        'MM.DD.YY',
                                        'M-D-YYYY',
                                        'M/D/YYYY',
                                        'M.D.YYYY',
                                        'MM.DD.YYYY',
                                        'MM-DD-YY',
                                        'MM/DD/YY',
                                        'M-D-YY',
                                        'M/D/YY',
                                        'M.D.YY',
                                        'MMDDYYYY',
                                        'MMDDYY'
                                    ]}
                                />

                            </Form.Item>

                            <Form.Item
                                label='End Date'
                                name='endDate'
                                {...validationProps('endDate')}
                            >
                                <DatePicker
                                    {...handleFocusEvents}
                                    disabled={formDisabledState.endDate}
                                    format={[
                                        'MM-DD-YYYY',
                                        'MM/DD/YYYY',
                                        'MM.DD.YY',
                                        'M-D-YYYY',
                                        'M/D/YYYY',
                                        'M.D.YYYY',
                                        'MM.DD.YYYY',
                                        'MM-DD-YY',
                                        'MM/DD/YY',
                                        'M-D-YY',
                                        'M/D/YY',
                                        'M.D.YY',
                                        'MMDDYYYY',
                                        'MMDDYY'
                                    ]}
                                />
                            </Form.Item>

                            <Form.Item
                                label='Describe the service to be provided.'
                                name='service'
                                {...validationProps('service')}
                            >
                                <Radio.Group
                                    name='service'
                                    {...handleMouseEvents}
                                    disabled={formDisabledState.service}
                                >
                                    <Radio value="individualTherapy">Individual Therapy</Radio>
                                    <Radio value="groupTherapy">Group Therapy</Radio>
                                    <Radio value="both">Both Individual and Group Therapy</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label='Describe why the service is medically necessary.'
                                name='disorder'
                                {...validationProps('disorder')}
                                {...handleFocusEvents}
                                onBlur={(e) => {
                                    const fieldValue = form.getFieldValue('disorder')
                                    const selectedValues = fieldValue.length > 0 ? fieldValue.join('|') : ''
                                    handleEditorEvents.onBlur('disorder', selectedValues)
                                }}
                            >
                                <Select
                                    name='disorder'
                                    placeholder="Please select"
                                    mode="multiple"
                                    disabled={formDisabledState.disorder}
                                    virtual={false}
                                    allowClear
                                    onBlur={(e) => e.preventDefault()}  //Prevent onBlur after every selection from list
                                >
                                    <Select.Option value='Anxiety'>Anxiety</Select.Option>
                                    <Select.Option value='Depression'>Depression</Select.Option>
                                    <Select.Option value='Anxiety/Depression'>Anxiety/Depression</Select.Option>
                                    <Select.Option value='Separation Anxiety'>Separation Anxiety</Select.Option>
                                    <Select.Option value='Attention-Deficit/Hyperactivity Disorder'>Attention-Deficit/Hyperactivity Disorder</Select.Option>
                                    <Select.Option value='Oppositional Defiant Disorder'>Oppositional Defiant Disorder</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label='Custom Medical Necessity'
                                name='medicalNecessity'
                                {...validationProps('medicalNecessity')}
                            >
                                <Editor
                                    formField='medicalNecessity'
                                    disabled={formDisabledState.medicalNecessity}
                                    {...handleEditorEvents}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Was this diagnosed by a doctor? '
                                name='diagnosed'
                                {...validationProps('diagnosed')}
                            >
                                <Radio.Group
                                    disabled={formDisabledState.diagnosed}
                                    {...handleMouseEvents}
                                    name='diagnosed'
                                >
                                    <Radio value="yes" onChange={onChangeDiagnosed}>Yes</Radio>
                                    <Radio value="no" onChange={onChangeDiagnosed}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {diagnosed === 'yes' ? <>                            <Form.Item
                                label='Physician Name'
                                name='doctorName'
                                {...validationProps(
                                    'doctorName'
                                )}
                            >
                                <Input
                                    disabled={formDisabledState.doctorName}
                                    {...handleFocusEvents}
                                />
                            </Form.Item>
                                <Form.Item
                                    label='Date of Diagnosis'
                                    name='dateOfDiagnosis'
                                    {...validationProps(
                                        'dateOfDiagnosis'
                                    )}
                                >
                                    <DatePicker
                                        {...handleFocusEvents}
                                        disabled={formDisabledState.dateOfDiagnosis}
                                        format={[
                                            'MM-DD-YYYY',
                                            'MM/DD/YYYY',
                                            'MM.DD.YY',
                                            'M-D-YYYY',
                                            'M/D/YYYY',
                                            'M.D.YYYY',
                                            'MM.DD.YYYY',
                                            'MM-DD-YY',
                                            'MM/DD/YY',
                                            'M-D-YY',
                                            'M/D/YY',
                                            'M.D.YY',
                                            'MMDDYYYY',
                                            'MMDDYY'
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Official Diagnosis'
                                    name='officialDiagnosis'
                                    {...validationProps('officialDiagnosis')}
                                >
                                    <Editor
                                        formField='officialDiagnosis'
                                        disabled={formDisabledState.officialDiagnosis}
                                        {...handleEditorEvents}
                                    />
                                </Form.Item>
                            </> : <></>}
                            <Form.Item
                                label='Minutes'
                                name='minutes'
                                {...validationProps(
                                    'minutes'
                                )}
                            >
                                <Input
                                    type='number'
                                    defaultValue={0}
                                    min={0}
                                    max={480}
                                    onWheel={e => e.target.blur()}
                                    {...handleFocusEvents}
                                    disabled={formDisabledState.minutes}
                                />
                            </Form.Item>
                            <Form.Item
                                name='frequency'
                                label='Frequency'
                                {...validationProps('frequency')}
                            >
                                <Select
                                    placeholder='Frequency'
                                    disabled={formDisabledState.frequency}
                                    {...handleFocusEvents}
                                // onChange={handleTypeChange}
                                >
                                    <Option value='Daily'>Daily</Option>
                                    <Option value='Weekly'>Weekly</Option>
                                    <Option value='Bi-Weekly'>Bi-Weekly</Option>
                                    <Option value='Monthly'>Monthly</Option>
                                    <Option value='Quarterly'>Quarterly</Option>
                                    <Option value='Other'>Other</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                // google maps integration at some point
                                label='Location'
                                name='location'
                                {...validationProps('location')}
                            >
                                <Input
                                    {...handleFocusEvents}
                                    disabled={formDisabledState.location}
                                />
                            </Form.Item>


                        </Card>
                        <Card
                            title='Behavior Concerns and Goals'
                            style={{
                                marginBottom: 30
                            }}
                        >
                            <Form.Item
                                label='Behavior(s)'
                                name='behavior'
                                {...validationProps('behavior')}
                                {...handleFocusEvents}
                                onBlur={(e) => {
                                    const fieldValue = form.getFieldValue('behavior')
                                    const selectedValues = fieldValue.length > 0 ? fieldValue.join('|') : ''
                                    handleEditorEvents.onBlur('behavior', selectedValues)
                                }}
                            >

                                <Select
                                    name='behavior'
                                    placeholder="Please select"
                                    mode="multiple"
                                    disabled={formDisabledState.behavior}
                                    virtual={false}
                                    allowClear
                                    onBlur={(e) => e.preventDefault()}  //Prevent onBlur after every selection from list
                                >
                                    <Select.Option value='Does not follow through with instructions'>Does not follow through with instructions</Select.Option>
                                    <Select.Option value='Avoids tasks that require sustained attention'>Avoids tasks that require sustained attention</Select.Option>
                                    <Select.Option value='Does not seem to listen with spoken to directly'>Does not seem to listen with spoken to directly</Select.Option>
                                    <Select.Option value='Decline in grades'>Decline in grades</Select.Option>
                                    <Select.Option value='Loss of interest in school'>Loss of interest in school</Select.Option>
                                    <Select.Option value='Frequently missing school or asking to stay home'>Frequently missing school or asking to stay home</Select.Option>
                                    <Select.Option value='Refuses to comply with adult request'>Refuses to comply with adult request</Select.Option>
                                    <Select.Option value='Fighting with peers'>Fighting with peers</Select.Option>
                                    <Select.Option value='Throwing objects'>Throwing objects</Select.Option>
                                    <Select.Option value='Argues with adults'>Argues with adults </Select.Option>
                                    <Select.Option value='Obscene language or gestures'>Obscene language or gestures</Select.Option>
                                    <Select.Option value='Inattentiveness'>Inattentiveness</Select.Option>
                                    <Select.Option value='Lack of concentration'>Lack of concentration</Select.Option>
                                    <Select.Option value='Difficulty sitting still'>Difficulty sitting still</Select.Option>
                                    <Select.Option value='Hyperactivity/nervousness'>Hyperactivity/nervousness</Select.Option>
                                    <Select.Option value='Excessive worry'>Excessive worry</Select.Option>
                                    <Select.Option value='Feelings of restlessness'>Feelings of restlessness</Select.Option>
                                    <Select.Option value='Inability to make and sustain friendships'>Inability to make and sustain friendships</Select.Option>
                                    <Select.Option value='Lack of motivation'>Lack of motivation</Select.Option>
                                    <Select.Option value='Loss of pleasure or interest'>Loss of pleasure or interest</Select.Option>
                                    <Select.Option value='Weight gain or loss'>Weight gain or loss</Select.Option>
                                    <Select.Option value='Sleeping difficulties'>Sleeping difficulties</Select.Option>
                                    <Select.Option value='Fatigue'>Fatigue</Select.Option>
                                    <Select.Option value='Thoughts of suicide or death'>Thoughts of suicide or death</Select.Option>
                                    <Select.Option value='Irritability and feelings of helplessness'>Irritability and feelings of helplessness</Select.Option>
                                    <Select.Option value='Change in friendships/ peer relationships'>Change in friendships/ peer relationships</Select.Option>
                                    <Select.Option value='Withdrawal'>Withdrawal</Select.Option>

                                </Select>
                            </Form.Item>
                            <Form.Item
                                label='Custom Behavior/Notes'
                                name='behaviorNotes'
                                {...validationProps('behaviorNotes')}
                            >
                                <Editor
                                    formField='behaviorNotes'
                                    disabled={formDisabledState.behaviorNotes}
                                    {...handleEditorEvents}
                                />
                            </Form.Item>
                            <Form.Item
                                label='Goal(s)'
                                name='goal'
                                {...validationProps('goal')}
                                {...handleFocusEvents}
                                onBlur={(e) => {
                                    const fieldValue = form.getFieldValue('goal')
                                    const selectedValues = fieldValue.length > 0 ? fieldValue.join('|') : ''
                                    handleEditorEvents.onBlur('goal', selectedValues)
                                }}
                            >
                                <Select
                                    name='goal'
                                    placeholder="Please select"
                                    mode="multiple"
                                    disabled={formDisabledState.goal}
                                    virtual={false}
                                    allowClear
                                    onBlur={(e) => e.preventDefault()}  //Prevent onBlur after every selection from list
                                >
                                    <Select.Option value='Student will develop organization skills that will aid the student to increase listening skills, staying on task and being organized.'>Student will develop organization skills that will aid the student to increase listening skills, staying on task and being organized.</Select.Option>
                                    <Select.Option value='Student will learn and implement anger management skills that reduce irritability and anger.'>Student will learn and implement anger management skills that reduce irritability and anger.</Select.Option>
                                    <Select.Option value='Student will increase listening skills and develop essential social skills that will enhance the quality of interpersonal relationships.'>Student will increase listening skills and develop essential social skills that will enhance the quality of interpersonal relationships.</Select.Option>
                                    <Select.Option value='Student will demonstrate improved attention and listening skills while having a respectful attitude toward his teacher.'>Student will demonstrate improved attention and listening skills while having a respectful attitude toward his teacher.</Select.Option>
                                    <Select.Option value='Student will learn techniques to reframe and redirect anxiety-producing stressors.'>Student will learn techniques to reframe and redirect anxiety-producing stressors.</Select.Option>
                                    <Select.Option value='Student will increase cooperative behavior by decreasing incidents of stealing at school.'>Student will increase cooperative behavior by decreasing incidents of stealing at school.</Select.Option>
                                    <Select.Option value='Student will reduce physical activity and impulsivity and become more attentive.'>Student will reduce physical activity and impulsivity and become more attentive.</Select.Option>
                                    <Select.Option value='Student will use constructive, healthy ways to release and/or express frustration and anger.'>Student will use constructive, healthy ways to release and/or express frustration and anger.</Select.Option>
                                    <Select.Option value='Student will reduce the overall level of worry and fear along with decreasing feelings of sadness.'>Student will reduce the overall level of worry and fear along with decreasing feelings of sadness.</Select.Option>
                                    <Select.Option value='Student will Increase the frequency of on task behavior.'>Student will increase the frequency of on task behavior</Select.Option>
                                    <Select.Option value='Student will increase their ability to effectively cope with the full variety of life’s anxieties.'>Student will increase their ability to effectively cope with the full variety of life’s anxieties.</Select.Option>

                                    <Select.Option value='Student will develop self management skills to achieve school and life success and develop positive relationships with others.'>Student will develop self-management skills to achieve school and life success and develop positive relationships with others.</Select.Option>

                                    <Select.Option value='Student will develop self-awareness and self-management skills to achive school and life success.'>Student will develop self-awareness and self-management skills to achive school and life success.</Select.Option>

                                    <Select.Option value='Student will use social-awareness and interpersonal skills to establish and maintain positive relationships.'>Student will use social-awareness and interpersonal skills to establish and maintain positive relationships.</Select.Option>

                                    <Select.Option value='Student will demonstrate decision-making skills and responsible behaviors in personal, school, and community contexts.'>Student will demonstrate decision-making skills and responsible behaviors in personal, school, and community contexts.</Select.Option>

                                </Select>
                            </Form.Item>
                            <Form.Item
                                label='Custom Goal/Notes'
                                name='goalNotes'
                                {...validationProps('goalNotes')}
                            >
                                <Editor
                                    formField='goalNotes'
                                    disabled={formDisabledState.goalNotes}
                                    {...handleEditorEvents}
                                />
                            </Form.Item>

                        </Card>

                        <Card
                            title='Services were dismissed due to:'
                            style={{
                                marginBottom: 30
                            }}
                        >
                            <Form.Item
                                label='Reason for dismissal'
                                name='dismissed'

                                {...validationProps('dismissed')}
                            >
                                <Checkbox.Group //Multi-select from: Observation Log/Data Charts/Tests/Other
                                    options={endOption}
                                    disabled={formDisabledState.dismissed}
                                    // onMouseDown={e => e.preventDefault()} //This prevents onFocus from firing that causes double render(flash type look)
                                    onChange={selectedValues => {
                                        const e = {
                                            target: {
                                                name: 'dismissed',
                                                value: selectedValues.toString()
                                            }
                                        }
                                        handleMouseEvents.onChange(e)
                                    }}
                                />
                            </Form.Item>

                        </Card>
                        {formError && <Paragraph type='danger'>{formError}</Paragraph>}
                        <StudentFormActions
                            query={PRINT_FORMHealthPlan}
                            extractDataProp='printStudentFormHealthPlan'
                            canSave={canSave}
                            handleActionEvents={handleActionEvents}

                            getFormFieldValues={getFormFieldValues}
                        />
                    </Form>
                )}
            </FormCollaboration>
        </>
    )
}

FormRecord.propTypes = {
    data: PropTypes.object.isRequired,
    studentFormId: PropTypes.object.isRequired,
    studentId: PropTypes.string.isRequired,
    formId: PropTypes.string.isRequired,
    schoolId: PropTypes.string.isRequired,
    studentMeetingId: PropTypes.string.isRequired,
    sis: PropTypes.object.isRequired,
    versionId: PropTypes.string.isRequired
}

const StudentFormHealthPlan = () => {
    // get current form information
    const { currentStudentInfo, currentEditableForm, studentMeetingId } =
        useContext(IepFormContext)
    const { studentId, schoolId } = currentStudentInfo
    const { studentFormId, formId, versionId } = currentEditableForm

    // Load SIS Data / Form Version Data
    const { loading: studentLoading, data: studentData } = useQuery(
        //QUERY_STUDENT_NAME,
        QUERY_STUDENT_COMMON_SIS,
        { variables: { id: studentId } }
    )
    const { loading: formLoading, data: formData } = useQuery(
        QUERY_HEALTHPLAN_VERSION,
        {
            variables: {
                studentFormId: studentFormId,
                studentId: studentId,
                schoolId: schoolId,
                formId: formId,
                studentMeetingId: studentMeetingId
            }
        }
    )
    //console.log("StudentFormHealthPlan-data returned by query: ", studentFormId, formData)
    const createInitialFormData = (studentInfo, versionInfo) => {
        let initialValues = {}
        if (!studentInfo || !versionInfo) return initialValues
        //console.log("Form3454-studentInfo: ", studentInfo, versionInfo)

        const { studentFormHealthPlan: form } = versionInfo
        const { student } = studentInfo
        const { formDate, studentFullName, studentDob, studentGrade, endDate, startDate, dateOfDiagnosis } = form

        initialValues = {
            ...form,
            formDate: formDate ? moment(formDate) : moment(Date.now()),
            studentDob: studentDob ? moment(studentDob).format("MM-DD-YYYY") : moment(Date.now().format("MM-DD-YYYY")),
            endDate: endDate ? moment(endDate) : moment(Date.now()),
            startDate: startDate ? moment(startDate) : moment(Date.now()),
            dateOfDiagnosis: dateOfDiagnosis ? moment(dateOfDiagnosis) : moment(Date.now()),
            studentFullName: studentFullName || student.fullName || '',
            studentGrade: studentGrade || student.grade || ''
        }
        //console.log("StudentFormHealthPlan-initialValues: ", initialValues)
        return initialValues
    }
    return (
        <FormErrorProvider>
            <FormContainer
                loading={studentLoading || formLoading}
                form={
                    <FormRecord
                        data={createInitialFormData(studentData, formData)}
                        studentFormId={studentFormId}
                        studentId={studentId}
                        formId={formId}
                        schoolId={schoolId}
                        studentMeetingId={studentMeetingId}
                        sis={studentData}
                        versionId={studentFormId}
                    />
                }
            />
        </FormErrorProvider>
    )
}
export default StudentFormHealthPlan
