import React, { useContext } from 'react'
import Page from '../../components/Page'
import LoginForm from '../../components/sections/login/LoginForm'
import { Typography, Layout } from 'antd'
import Home from './Home'
// contexts
import AppContext from '../../utils/appProvider'

const Login = () => {

  const app = useContext(AppContext)
  return (
    <Layout className='content-layout' >
      <Page >
        <Home />
      </Page>
    </Layout>
  )
}

export default Login
